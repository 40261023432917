import React, { useState, useEffect } from "react";

export const Header = (props) => {
  // Detect mobile using a 768px breakpoint
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  // Video style: rendered as a block element so its full width is visible.
  // On mobile, we add a top margin to shift it further down.
  const videoStyle = {
    width: "100%",
    height: "auto",
    display: "block",
    marginTop: isMobile ? "110px" : "0", // increased from 80px to 100px
  };

  // Header container positioned relative to allow overlay positioning.
  const headerStyle = {
    position: "relative",
    overflow: "hidden",
  };

  // Overlay: covers the entire video area.
  // Its top offset matches the mobile video margin.
  const overlayStyle = {
    position: "absolute",
    top: isMobile ? "100px" : "0", // increased to match video shift
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.3)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <header id="header" style={headerStyle}>
      <video autoPlay muted loop playsInline style={videoStyle}>
        <source src="/univerzal2.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div style={overlayStyle}>
        <div className="container">
          <div className="row">
            <div
              className="col-md-8 col-md-offset-2 intro-text"
              style={{ textAlign: "center", color: "#fff" }}
            >
              <h1>
                {props.data ? props.data.title : "Loading"}
                <span></span>
              </h1>
              <p>{props.data ? props.data.paragraph : "Loading"}</p>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
