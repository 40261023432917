import { useState, useEffect, useContext } from 'react';
import { Navigation } from './components/navigation';
import { Header } from './components/header';
import { Features } from './components/features';
import { About } from './components/about';
import { Gallery } from './components/gallery';
import { Testimonials } from './components/testimonials';
import { Footer } from './components/footer';
import SmoothScroll from 'smooth-scroll';
import { LanguageProvider, LanguageContext } from './contexts/LanguageContext';
// Import JSON data
import JsonDataEN from './data/data.en.json';
import JsonDataDE from './data/data.de.json';
import JsonDataBS from './data/data.bs.json';

const AppContent = () => {
  const { language } = useContext(LanguageContext);
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    // Reset scroll position to the top on component mount
    window.scrollTo(0, 0);

    // Initialize SmoothScroll after a short delay
    const smoothScroll = new SmoothScroll('a[href*="#"]', {
      speed: 1000,
      speedAsDuration: true,
    });

    return () => {
      // Cleanup SmoothScroll instance if needed
      smoothScroll.destroy();
    };
  }, []);

  useEffect(() => {
    switch (language) {
      case 'de':
        setLandingPageData(JsonDataDE);
        break;
      case 'bs':
        setLandingPageData(JsonDataBS);
        break;
      default:
        setLandingPageData(JsonDataEN);
    }
  }, [language]);

  return (
    <div>
      <Navigation data={landingPageData.Navigation} />
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Gallery data={landingPageData.Gallery?.items} />
      <Testimonials data={landingPageData.Testimonials} />
      <Footer data={landingPageData.Footer} />
    </div>
  );
};

const App = () => {
  return (
    <LanguageProvider>
      <AppContent />
    </LanguageProvider>
  );
};

export default App;