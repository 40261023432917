import React, { createContext, useState } from 'react';

// Create a context with a default language of English ('en')
export const LanguageContext = createContext({
  language: 'en',
  setLanguage: () => {}
});

// Provider component to wrap your app and manage language state
export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en'); // default is English

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
