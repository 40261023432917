import React from 'react';

export const Footer = ({ data }) => {
  return (
    <footer id="footer" className="footer">
      <div className="container text-center">
        <p>
          <strong>
            {data && data.text ? data.text : "© 2025 Renesalis Packaging."}
          </strong>
        </p>
      </div>
    </footer>
  );
};

export default Footer;


