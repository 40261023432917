import React, { useContext } from 'react';
import { LanguageContext } from '../contexts/LanguageContext';

export const Navigation = (props) => {
  // Get the navigation labels from props.data; fallback to defaults if not available.
  const navLabels = props.data || {
    features: 'Features',
    gallery: 'Gallery',
    testimonials: 'Testimonials'
  };

  // Get the current language and updater function from context
  const { language, setLanguage } = useContext(LanguageContext);

  // Labels for the language switch options
  const languageLabels = {
    en: 'ENG',
    de: 'DE',
    bs: 'BS'
  };

  // Helper function to change language
  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a
            className="navbar-brand page-scroll"
            href="#page-top"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {/* Logo Image */}
            <img
              src="/logo.jpg"
              alt="Logo"
              style={{
                maxHeight: '50px',
                width: 'auto',
                marginRight: '10px'
              }}
              className="img-responsive"
            />
            <span>RENESALIS PACKAGING</span>
          </a>
        </div>

        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#features" className="page-scroll">
                {navLabels.features}
              </a>
            </li>
            <li>
              <a href="#portfolio" className="page-scroll">
                {navLabels.gallery}
              </a>
            </li>
            <li>
              <a href="#testimonials" className="page-scroll">
                {navLabels.testimonials}
              </a>
            </li>
            {/* Language Switch Dropdown */}
            <li className="dropdown">
              <a
                href="#"
                className="dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {languageLabels[language]} <span className="caret"></span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleLanguageChange('en');
                    }}
                  >
                    English
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleLanguageChange('de');
                    }}
                  >
                    Deutsch
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleLanguageChange('bs');
                    }}
                  >
                    Bosanski
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
