import React, { useState, useCallback, useContext } from "react";
import ImageViewer from "react-simple-image-viewer";
import { LanguageContext } from "../contexts/LanguageContext";

export const Gallery = (props) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const { language } = useContext(LanguageContext);

  // Default data if props.data is not loaded yet
  const defaultData = [
    { thumb: "img/portfolio/1.jpg", title: "Loading..." },
    { thumb: "img/portfolio/2.jpg", title: "Loading..." },
    { thumb: "img/portfolio/3.jpg", title: "Loading..." },
    { thumb: "img/portfolio/4.jpg", title: "Loading..." },
    { thumb: "img/portfolio/6.jpg", title: "Loading..." },
    { thumb: "img/portfolio/8.jpg", title: "Loading..." },
    { thumb: "img/portfolio/10.jpg", title: "Loading..." },
    { thumb: "img/portfolio/11.jpg", title: "Loading..." },
    { thumb: "img/portfolio/12.jpg", title: "Loading..." },
    { thumb: "img/portfolio/13.jpg", title: "Loading..." },
    { thumb: "img/portfolio/14.jpg", title: "Loading..." },
    { thumb: "img/portfolio/16.jpg", title: "Loading..." },
    { thumb: "img/portfolio/18.jpg", title: "Loading..." },
    { thumb: "img/portfolio/19.jpg", title: "Loading..." },
    { thumb: "img/portfolio/20.jpg", title: "Loading..." },
    { thumb: "img/portfolio/21.jpg", title: "Loading..." },
    { thumb: "img/portfolio/22.jpg", title: "Loading..." },
    { thumb: "img/portfolio/23.jpg", title: "Loading..." },
    { thumb: "img/portfolio/24.jpg", title: "Loading..." },
    { thumb: "img/portfolio/25.jpg", title: "Loading..." },
    { thumb: "img/portfolio/26.jpg", title: "Loading..." }
  ];

  const data = props.data || defaultData;
  const images = data.map(obj => obj.thumb.replace("-small", "-large"));

  const openImageViewer = useCallback(index => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const sectionTitle = {
    en: "Our products",
    de: "Unsere Produkte",
    bs: "Proizvodi"
  }[language];

  const sectionDescription = {
    en: "Below you will find some of the products we offer. We are specialized in custom made high quality packaging solutions.",
    de: "Hier finden Sie einige unserer Verpackungslösungen. Alle Producte werden massgeschneidert hergestellt.",
    bs: "Ovo su naša ambalažna rješenja."
  }[language];

  const chunkArray = (array, chunkSize) => {
    const results = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      results.push(array.slice(i, i + chunkSize));
    }
    return results;
  };

  const rows = chunkArray(data, 3);

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{sectionTitle}</h2>
          <p>{sectionDescription}</p>
        </div>

        {rows.map((rowData, rowIndex) => (
          <div className="row" key={`row-${rowIndex}`}>
            {rowData.map(({ title, thumb }, index) => {
              const overallIndex = rowIndex * 3 + index;
              return (
                <div
                  key={`${thumb}-${language}`}
                  onClick={() => openImageViewer(overallIndex)}
                  className="col-sm-6 col-md-4 col-lg-4"
                >
                  <div className="portfolio-item cursor">
                    <div className="hover-bg" style={{ position: 'relative' }}>
                      <div 
                        className="hover-text" 
                        style={{ 
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '20px',
                          backgroundColor: 'rgba(0, 0, 0, 0.8)',
                          borderRadius: '4px',
                          cursor: 'pointer'
                        }}
                      >
                        <h4 style={{ 
                          textTransform: 'none',
                          margin: 0,
                          lineHeight: '1.4',
                          fontSize: '1.3rem',
                          color: '#fff',
                          textAlign: 'center',
                          wordBreak: 'break-word'
                        }}>{title}</h4>
                      </div>
                      <img
                        src={thumb}
                        className="img-responsive"
                        alt={title}
                        loading="lazy"
                        style={{
                          width: '100%',
                          height: '300px',
                          objectFit: 'cover',
                          borderRadius: '4px'
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ))}

        {isViewerOpen && (
          <ImageViewer
            src={images}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
              zIndex: 9999
            }}
            currentIndex={currentImage}
            onClose={closeImageViewer}
            closeOnClickOutside={true}
            disableScroll={false}
          />
        )}
      </div>
    </div>
  );
};